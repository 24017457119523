<template>
  <div class="page-container cbhpm-table-page">
    <portal to="page-name">Cadastro de tabelas CBHPM</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i>Registro ativo
          </label>
        </div>
        <h1 class="card-title">Cadastro de tabelas CBHPM</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg" v-if="loading" />
        <template v-else>
          <div class="columns form-group">
            <div class="column col-12 form-group"
                 :class="{'has-error': $v.form.name.$error}">
              <label for="name" class="form-label">Nome</label>
              <input type="text" id="name" name="name"
                     v-model="form.name" class="form-input"
                     @blur="$v.form.name.$touch()">
              <template v-if="$v.form.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.name.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.editionCode.$error}">
              <label class="form-label">Edição</label>
              <select class="form-select"
                      v-model="form.editionCode"
                      @blur="$v.form.editionCode.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="(item, i) in cbhpmEditions"
                        :value="item.code" :key="i">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.editionCode.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.editionCode.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.calculationCode.$error}">
              <label class="form-label">Tabela de cálculo</label>
              <select class="form-select"
                      v-model="form.calculationCode"
                      @blur="$v.form.calculationCode.$touch()"
                      @change="setValues">
                <option value="">[Selecione]</option>
                <option v-for="(item, i) in cbhpmItems"
                        :value="item.code" :key="i">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.calculationCode.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.calculationCode.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-4 col-md-4 col-sm-12 form-group"
                 :class="{'has-error': $v.form.operationalCost.$error}">
              <label class="form-label">Custo Operacional (UCO)</label>
              <dx-input-number class="form-input"
                               v-model="form.operationalCost"
                               @blur="$v.form.operationalCost.$touch()"
                               :precision="4"
              />
              <template v-if="$v.form.operationalCost.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.operationalCost.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.operationalCost.minValue">Valor obrigatório</div>
              </template>
            </div>
            <div class="column col-4 col-md-4 col-sm-12 form-group">
              <label class="form-label">Valor m² do Filme</label>
              <dx-input-number class="form-input"
                               v-model="form.film"
                               :precision="4"
              />
            </div>
            <div class="column col-4 col-md-4 col-sm-12 form-group">
              <label class="form-label">Coeficiente Porte</label>
              <div class="input-group">
                <select class="form-select"
                        v-model="form.factor"
                        style="max-width: 150px">
                  <option value="+">Acréscimo</option>
                  <option value="-">Decréscimo</option>
                </select>
                <dx-input-number class="form-input"
                                 v-model="form.coefficient"
                                 :precision="4"
                />
                <button class="btn btn-icon btn-action btn-warning input-group-btn"
                        @click="setValues">
                  <fa-icon :icon="['fal', 'sync']" />
                </button>
              </div>
            </div>
          </div>
          <div class="postage-items" v-if="form.postageItems.length > 0">
            <div class="divider" />
            <h6>Portes</h6>
            <div class="columns">
              <div class="column col-4 col-md-4 col-sm-12">
                <div v-for="(item, i) in form.postageItems.slice(0, 14)" :key="i">
                  <div class="input-group">
                    <span class="input-group-addon">{{ item.postage }}</span>
                    <dx-input-number class="form-input"
                       v-model="item.value"
                       :class="{'is-error': $v.form.postageItems.$each[i].value.$error}"
                       :precision="2" />
                  </div>
                </div>
              </div>
              <div class="column col-4 col-md-4 col-sm-12">
                <div v-for="(item, i) in form.postageItems.slice(14, 28)" :key="i">
                  <div class="input-group">
                    <span class="input-group-addon">{{ item.postage }}</span>
                    <dx-input-number class="form-input"
                                     v-model="item.value"
                                     :class="{'is-error': $v.form.postageItems.$each[
                                     i + 14
                                   ].value.$error}"
                                     :precision="2" />
                  </div>
                </div>
              </div>
              <div class="column col-4 col-md-4 col-sm-12">
                <div v-for="(item, i) in form.postageItems.slice(28, 42)" :key="i">
                  <div class="input-group">
                    <span class="input-group-addon">{{ item.postage }}</span>
                    <dx-input-number class="form-input"
                                     v-model="item.value"
                                     :class="{'is-error': $v.form.postageItems.$each[
                                     i + 28
                                   ].value.$error}"
                                     :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { minValue, required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/cbhpm-tables',
      isNew: true,
      loading: false,
      saving: false,
      cbhpmEditions: [
        { code: '2004', name: 'CBHPM 2004 (3ª Edição)' },
        { code: '2005', name: 'CBHPM 2005 (4ª Edição)' },
        { code: '2008', name: 'CBHPM 2008 (5ª Edição)' },
        { code: '2010', name: 'CBHPM 2010' },
        { code: '2012', name: 'CBHPM 2012' },
        { code: '2014', name: 'CBHPM 2014' },
        { code: '2016', name: 'CBHPM 2016' },
      ],
      cbhpmItems: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.loadCbhpmCalculations();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        active: { required },
        editionCode: { required },
        calculationCode: { required },
        operationalCost: { required, minValue: minValue(0.0001) },
        postageItems: {
          $each: {
            value: { required, minValue: minValue(0.0001) },
          },
        },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          data.editionCode = data.edition.code;
          data.calculationCode = data.calculation.code;
          delete data.edition;
          delete data.calculation;
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadCbhpmCalculations() {
      const params = {
        limit: 0,
      };

      return this.$http.get('/cbhpm-calculations', { params })
        .then(({ data }) => {
          this.cbhpmItems = data.items;
        })
        .catch(() => {});
    },
    setValues() {
      if (!this.form.calculationCode) {
        return;
      }

      this.form.postageItems = [];

      const found = this.cbhpmItems
        .find(({ code }) => code === this.form.calculationCode);

      this.form.operationalCost = found.operationalCost;
      this.form.film = found.film;
      this.form.postageItems = this.clone(found.postageItems, true);

      if (this.form.coefficient > 0) {
        let percent = this.form.coefficient || 0;

        if (this.form.factor === '-') {
          percent *= -1;
        }

        this.form.postageItems.forEach((item) => {
          item.value += (item.value * (percent / 100));
          return item;
        });
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);
      data.edition = this.cbhpmEditions
        .find(({ code }) => code === data.editionCode);
      data.calculation = this.cbhpmItems
        .find(({ code }) => code === data.calculationCode);
      delete data.editionCode;
      delete data.calculationCode;

      const request = this.isNew
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${this.form.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/clinical${this.path}/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo!');
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        editionCode: '',
        calculationCode: '',
        operationalCost: '',
        factor: '+',
        film: '',
        coefficient: '',
        postageItems: [],
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';

  .cbhpm-table-page {
    .postage-items {
      .input-group {
        margin-bottom: $layout-spacing-sm;
      }
      .input-group-addon {
        color: $gray-color-dark;
        font-weight: bold;
        text-align: center;
        width: 50px;
      }
    }
  }
</style>
